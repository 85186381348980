import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import ImageTagList, {
  IImageTagListProps,
} from "../../feature/ImageTagList/ImageTagList";

export interface ISectionImageTagListProps {
  theme: Theme;
  imageTagList: IImageTagListProps;
}

const SectionImageTagList: FC<ISectionImageTagListProps> = ({ theme, imageTagList }) => (
  <Section component={SectionImageTagList.name} theme={theme} size={theme === "default" ? "none" : "xl"}>
    <Container>
      <ImageTagList {...imageTagList} />
    </Container>
  </Section>
);

export default SectionImageTagList;
