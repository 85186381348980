import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { Theme } from "apps/website/maps/Theme.map";
import {
  IExternalReview,
  IStars,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview";
import {
  IArticleCard,
} from "apps/website/components/feature/Card/ArticleCard/ArticleCard";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import ExternalReviewCarouselSection, {
  ICarouselBanner,
} from "apps/website/components/section/SectionCarousel/SectionExternalReviewCarousel/SectionExternalReviewCarousel";
import ArticleCardCarouselSection from
  "apps/website/components/section/SectionCarousel/SectionArticleCardCarousel/SectionArticleCardCarousel";
// import { IStoryBlokArticleMain } from "apps/website/pages/articles/[[...slug]]";
import TrustPilotCarouselSection from
  "apps/website/components/section/SectionCarousel/SectionTrustPilotCarousel/SectionTrustPilotCarousel";
import {
  STORYBLOK_ARTICLES_PRETTY_ROOT,
} from "apps/website/constants/storyblok";
import { Layout } from "apps/website/components/feature/Review/Review.map";
import SectionTestimonialCarousel from
  "apps/website/components/section/SectionCarousel/SectionTestimonialCarousel/SectionTestimonialCarousel";

import { ReviewStarStyle } from "../../feature/Review/Review";
import {
  ICondensedImageCardCarouselItemProps,
} from "../../feature/Carousel/CondensedImageCardCarousel/CondensedImageCardCarouselItem/CondensedImageCardCarouselItem";
import SectionCondensedImageCardCarousel from "../../section/SectionCarousel/SectionCondensedImageCardCarousel/SectionCondensedImageCardCarousel";

export interface IStoryBlokReview {
  title: string;
  body: string;
  stars: IStars;
  hide_stars: boolean;
  author_logo: IStoryBlokImage;
  author: string;
}

export interface IStoryBlokTestimonial {
  _uid: string;
  title?: string;
  body?: string;
  stars?: IStars;
  star_style?: ReviewStarStyle;
  hide_stars: boolean;
  image: IStoryBlokImage;
  author?: string;
}

export interface IStoryBlokReviews {
  content: IStoryBlokReview;
}

export interface IStoryBlokTestimonialReview {
  content: IStoryBlokTestimonial;
}

interface IStoryBlokReviewCarousel {
  reviews: IStoryBlokReviews[];
  layout?: Layout;
  show_banner: boolean
  banner_text: string;
  banner_link: string;
  banner_link_text: string;
}

interface IStoryBlokCarouselTestimonials {
  reviews: IStoryBlokTestimonialReview[];
  title: string;
  theme?: Theme;
  layout?: Layout;
}

interface IStoryBlokCarouselTrustPilot {
  reviews: IStoryBlokReviews[];
  title: string;
  theme?: Theme;
  layout?: Layout;
}

interface IStoryBlokCarouselCondensedImageCardItem {
  image: IStoryBlokImage;
  theme: Theme;
  title: string;
}

interface IStoryBlokCarouselCondensedImageCards {
  items: IStoryBlokCarouselCondensedImageCardItem[];
}

interface IStoryBlokSectionCarouselCondensedImageCards {
  carousel: [IStoryBlokCarouselCondensedImageCards];
  theme: Theme;
}

export interface IStoryBlokArticleCardsCarousel {
  title: string;
  body: string;
  cards: any[]; // eslint-disable-line
  theme: Theme;
}

export const getExternalReviewsFromIStoryBlokReview = (content: IStoryBlokReview): IExternalReview => ({
  title: content?.title || "",
  body: content?.body || "",
  stars: Number(content?.stars) as IStars || 1,
  hideStars: !!content?.hide_stars,
  authorLogo: getStoryBlokImage(content?.author_logo, true, ImageMax.ICON_MD),
  author: content?.author || undefined,
});

export const getExternalReviewsFromIStoryBlokReviews = (reviews: IStoryBlokReviews[]): IExternalReview[] => (
  reviews.map((
    { content },
  ) => getExternalReviewsFromIStoryBlokReview(content))
);

const getCondensedImageCardItems = (
  items: IStoryBlokCarouselCondensedImageCardItem[],
): ICondensedImageCardCarouselItemProps[] => items.map((item) => ({
  title: item.title,
  theme: item.theme,
  image: getStoryBlokImage(item.image),
}));

export const StoryBlokCarouselReviewExternal = ({ blok }: IStoryBlokComponent<IStoryBlokReviewCarousel>) => {

  const items = getExternalReviewsFromIStoryBlokReviews(blok.reviews);

  const banner: ICarouselBanner = {
    title: blok.banner_text,
    link: blok.banner_link,
    linkText: blok.banner_link_text,
    show: blok.show_banner,
  };
  return (
    <ExternalReviewCarouselSection
      items={items}
      layout={blok.layout}
      banner={banner}
    />
  );
};

export const StoryBlokCarouselArticleCards = ({ blok }: IStoryBlokComponent<IStoryBlokArticleCardsCarousel>) => {

  const items: IArticleCard[] = blok.cards.map((article) => (
    {
      title: article.content.title,
      description: article.content.summary,
      image: getStoryBlokImage(article.content.preview_image, true, ImageMax.CONTAINER_SM),
      tag: {
        text: article.content.categories?.[0]?.content?.name ?? "",
        theme: article.content.categories?.[0]?.content?.theme,
      },
      link: `/${STORYBLOK_ARTICLES_PRETTY_ROOT}/${article.slug}`,
      readTime: article.content.read_time,
    }
  ));

  return (
    <ArticleCardCarouselSection
      items={items}
      title={blok.title}
      body={blok.body}
      theme={blok.theme}
    />
  );
};

export const StoryBlokCarouselTestimonials = ({ blok }: IStoryBlokComponent<IStoryBlokCarouselTestimonials>) => (
  <SectionTestimonialCarousel
    title={blok.title}
    theme={blok.theme}
    items={blok.reviews?.map(({ content }) => ({
      id: content._uid,
      title: content?.title || "",
      body: content?.body || "",
      stars: Number(content?.stars) as IStars || 1,
      hideStars: !!content?.hide_stars,
      starStyle: content?.star_style,
      author: content?.author,
      image: getStoryBlokImage(content.image, true, ImageMax.CONTAINER_SM),
    })) || []}
  />
);

export const StoryBlokCarouselTrustPilot = ({ blok }: IStoryBlokComponent<IStoryBlokCarouselTrustPilot>) => {
  const items = getExternalReviewsFromIStoryBlokReviews(blok.reviews);
  return (
    <TrustPilotCarouselSection title={blok.title} items={items} layout={blok.layout} theme={blok.theme}/>
  );
};

export const StoryBlokCarouselCondensedImageCards = (
  { blok }: IStoryBlokComponent<IStoryBlokSectionCarouselCondensedImageCards>,
) => {
  const items = getCondensedImageCardItems(blok.carousel[0].items);
  return (
    <SectionCondensedImageCardCarousel items={items} theme={blok.theme}/>
  );
};
