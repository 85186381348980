import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import CondensedReviewList, {
  ICondensedReviewListProps,
} from "../../feature/CondensedReviewList/CondensedReviewList";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Text from "../../base/Text/Text";
import Spacer from "../../layout/Spacer/Spacer";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import IconTextList, {
  IIconTextList,
} from "../../feature/IconTextList/IconTextList";

export interface ISectionCondensedReviewListProps {
  title: string;
  iconTextList: IIconTextList;
  reviews: ICondensedReviewListProps;
  theme?: Theme;
}

const SectionCondensedReviewList: FC<ISectionCondensedReviewListProps> = ({ title, iconTextList, reviews, theme = "default" }) => (
  <Section component={SectionCondensedReviewList.name}

    theme={theme}
    size={theme === "default" ? "none" : "xl"}
  >
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center" data-testid="SectionCondensedReviewListTitle">{ title }</Text>
          <Spacer size="xl" />
        </>
      ) }
      <Grid gap="none">
        <Column>
          <IconTextList {...iconTextList} />
          <Spacer size="xl" />
        </Column>
        <Column>
          <CondensedReviewList { ...reviews} />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionCondensedReviewList;
