import React from "react";

import {
  ImageMax,
  getStoryBlokAdvancedImages,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokAdvancedImages,
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import SplitSplash, {
  Direction,
} from "apps/website/components/section/SectionSplitSplash/SectionSplitSplash";

export interface IStoryBlokSplitSplash {
  title: string;
  image: IStoryBlokImage
  advanced_images: IStoryBlokAdvancedImages;
  cta: [IStoryBlokCta];
  direction: Direction;
  theme: Theme;
}

export const StoryBlokSplitSplash = ({ blok }: IStoryBlokComponent<IStoryBlokSplitSplash>) => (
  <SplitSplash
    title={blok.title}
    image={getStoryBlokImage(blok.image, true, ImageMax.HALF_SCREEN)}
    advancedImages={getStoryBlokAdvancedImages(blok.advanced_images)}
    cta={getStoryBlokCta(blok.cta[0])}
    direction={blok.direction}
    theme={blok.theme}
  />
);
