import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import {
  AlertPrompt,
  IAlertPromptProps,
} from "../../feature/AlertPrompt/AlertPrompt";

export interface ISectionAlertPromptProps {
  alert: IAlertPromptProps;
  theme: Theme;
}

const SectionAlertPrompt: FC<ISectionAlertPromptProps> = ({ alert, theme }) => (
  <Section component={SectionAlertPrompt.name} theme={theme} size={theme === "default" ? "none" : "xl"}>
    <Container>
      <AlertPrompt {...alert} />
    </Container>
  </Section>
);

export default SectionAlertPrompt;
