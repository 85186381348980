import { FC, memo, useCallback } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Container from "apps/website/components/layout/Container/Container";
import Section, {
  ISectionSize,
} from "apps/website/components/layout/Section/Section";
import Image from "apps/website/components/base/Image/Image";
import ImageText, {
  IImageText,
} from "apps/website/components/feature/ImageText/ImageText";

import { Size } from "../../layout/Spacer/Spacer.map";
import { absoluteImageMap } from "../../feature/ImageText/ImageText.map";

export interface ISectionImageText extends IImageText {
  theme?: Theme;
}

const SectionImageText: FC<ISectionImageText> = (
  {
    title,
    titleTag = "h2",
    fontSize = "default",
    size = "auto",
    subtitle,
    body,
    footer,
    image,
    advancedImages,
    disableImageLazyLoading = false,
    cta,
    direction = "default",
    theme = "default",
    layout = "default",
  },
) => {

  const getSectionSize = useCallback((): Size | ISectionSize => {
    if (theme === "default") return "none";
    return { top: layout === "secondary" ? "xl-alt" : "xl", bottom: layout === "secondary" && [ "reverse", "reverseMobile" ].includes(direction) ? "xl-alt" : "xl" };
  }, [ theme, layout ]);

  return (
    <Section component="SectionImageText" theme={theme} className="relative" size={getSectionSize()}>
      { layout === "secondary" && (
        <div className={`hidden lg:block absolute top-0 w-1/2 h-full ${absoluteImageMap[direction]}`}>
          <Image
            image={image}
            alt={image.alt || title}
            advancedImages={advancedImages}
            lazy={!disableImageLazyLoading}
            cover
          />
        </div>
      ) }
      <Container>
        <ImageText
          title={title}
          titleTag={titleTag}
          fontSize={fontSize}
          size={size}
          subtitle={subtitle}
          body={body}
          footer={footer}
          image={image}
          advancedImages={advancedImages}
          disableImageLazyLoading={disableImageLazyLoading}
          cta={cta}
          direction={direction}
          layout={layout}
        />
      </Container>
    </Section>
  );
};

export default memo(SectionImageText);
