import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";

import {
  IStoryBlokIconCallout,
  StoryBlokIconCallout,
} from "../StoryBlokIconCallout";

export const StoryBlokFormIconCallout = (component: IStoryBlokComponent<IStoryBlokIconCallout>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokIconCallout> = {
    blok: {
      ...component.blok,
      callout: [
        {
          ...component.blok.callout[0],
        },
      ],
      richtextOptions: {
        interpolation: {
          values: getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", component.linkingId || "0", component.linkingId ? "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        },
        tags: {
          MARK_CODE: {
            display: "default",
          },
        },
      },
    },
  };

  return (
    <>
      <StoryBlokIconCallout {...formComponent} />
    </>
  );
};
