import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import SectionBrandComparisonTable from
  "apps/website/components/section/SectionBrandComparisonTable/SectionBrandComparisonTable";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

export interface IStoryBlokBrandComparisonTable {
  title: string;
  subtitle: string;
  body: string;
  theme: Theme
  source?: string;
  highlight_katkin?: boolean;
}

export const StoryBlokBrandComparisonTable = ({ blok }: IStoryBlokComponent<IStoryBlokBrandComparisonTable>) => (
  <SectionBrandComparisonTable
    title={blok.title}
    subtitle={blok.subtitle}
    body={blok.body}
    theme={blok.theme}
    source={ blok.source ? renderStoryBlokRichText(blok.source, { unwrapResolvers: true, links: { display: "default" } }) : undefined}
    highlightKatKin={blok.highlight_katkin}
  />
);
