import {
  SbReactComponentsMap,
  storyblokInit,
  apiPlugin,
} from "@storyblok/react";

import { PublicConfig } from "../config/public";

export function setupStoryBlok(components?: SbReactComponentsMap) {
  // Surpress package console error
  /*
    This is used by Storyblok bridge which is for storyblok bridge which is not used in Production* so
    causes a console error which is being flagged by lighthouse.

    *See property "bridge" in "storyblokInit".
  */
  if (PublicConfig.STORYBLOK_ENV !== "draft" && typeof window !== "undefined") {
    window.storyblokRegisterEvent = () => {};
  }

  storyblokInit({
    accessToken: PublicConfig.STORYBLOK_API_TOKEN,
    // Only enable the bridge if storyblok env is draft
    bridge: PublicConfig.STORYBLOK_ENV === "draft",
    use: [ apiPlugin ],
    apiOptions: {
      cache: {
        type: "memory",
        clear: "auto",
      },
    },
    components,
  });
}
