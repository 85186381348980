"use client";

import { useCookies } from "react-cookie";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";

import { DisplayState } from "@/constants/state";
import {
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  getActionTrackingInfo,
} from "apps/website/utils/tracking/getActionTrackingInfo";
import { useDiscountStore } from "@./state";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import { IStoryBlokStickyCTA, StoryBlokStickyCTA } from "../StoryBlokStickyCTA";

export const StoryBlokFormStickyCta = (component: IStoryBlokComponent<IStoryBlokStickyCTA>) => {

  const posthog = usePostHog();
  const [ state, setState ] = useState<DisplayState>(DisplayState.READY);
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);
  const { discountCodes } = useDiscountStore();

  const formsActionsAPI = useAPI().FormActions;

  const { chainQueryParamsToUrl } = useQueryParams();

  const goToSuccessPage = () => {
    if (!component.blok.action_redirect?.url) return;
    window.location.href = chainQueryParamsToUrl(component.blok.action_redirect.url);
  };

  const performAction = async () => {

    if (!component.flowId || !component.blok.action) return;

    setState(DisplayState.PROCESSING);
    try {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(component.flowId);
      fieldSubmitValuesMap.set("discountCode", { value: discountCodes.find((dc) => dc.flowId === component.flowId)?.discountCode ?? "" });
      const resp = await formsActionsAPI.performAction(
        component.blok.action,
        fieldSubmitValuesMap,
        getFlowProduct(component.flowId),
        component.flowId,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
      );
      if (!resp.success) {
        setState(DisplayState.ERROR);
      } else {
        setState(DisplayState.COMPLETE);
        goToSuccessPage();
      }
    } catch (error) {
      console.error("CTA Error", error);
      setState(DisplayState.ERROR);
    }
  };

  const formComponent: IStoryBlokComponent<IStoryBlokStickyCTA> = {
    blok: {
      ...component.blok,
      onClick: performAction,
      state,
    },
  };

  return (
    <>
      <StoryBlokStickyCTA {...formComponent} />
    </>
  );
};
