import { FC, memo } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import ExternalReview, {
  IExternalReview,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview";
import {
  rotationClassList,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import Container from "apps/website/components/layout/Container/Container";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { Layout } from "apps/website/components/feature/Review/Review.map";
import { getCardTheme } from "apps/website/utils/content/carousels";

export interface ISectionTrustPilotReviewWall {
  reviews: IExternalReview[];
  footer: string;
  theme?: Theme;
  title?: string;
  layout?: Layout
}

const SectionTrustPilotReviewWall: FC<ISectionTrustPilotReviewWall> = ({ title, footer, reviews, theme = "light-grey", layout }) => (
  <Section component="SectionTrustPilotReviewWall" theme={theme} size={theme === "default" ? "none" : "xl"}>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
            { title }
          </Text>
          <Spacer size="2xl" />
        </>
      ) }
      <Grid gap="lg">
        { reviews.map((review) => {
          const rotationClass = rotationClassList[Math.floor(Math.random() * rotationClassList.length)];
          return (
            <Column key={review.title} spans={{ md: 6, lg: 4 }}>
              <ExternalReview {...review} theme={getCardTheme(theme)} layout={layout} className={`h-full ${rotationClass}`}/>
            </Column>
          );
        }) }
      </Grid>
      <Spacer size="xl" />
      <div data-footer>
        <Grid>
          <Column>
            <Text tag="div" align="center">{ footer }</Text>
          </Column>
        </Grid>
      </div>
    </Container>
  </Section>
);

export default memo(SectionTrustPilotReviewWall);
