import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import TagList, { ITagListProps } from "../../feature/TagList/TagList";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

export interface ISectionTagListProps extends ITagListProps {
  theme?: Theme;
}

const SectionTagList: FC<ISectionTagListProps> = ({ theme = "default", items }) => (
  <Section component={SectionTagList.name} theme={theme} size={theme === "default" ? "none" : "xl"}>
    <Container size="sm">
      <Grid>
        <Column align="center">
          <TagList items={items} />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionTagList;
