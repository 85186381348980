import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Section from "apps/website/components/layout/Section/Section";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Container from "apps/website/components/layout/Container/Container";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import HoverList from "apps/website/components/feature/HoverList/HoverList";
import {
  IHoverListItem,
} from "apps/website/components/feature/HoverList/HoverListItem/HoverListItem";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Button from "apps/website/components/base/Button/Button";

export interface ISectionHoverList {
  title?: string;
  items: IHoverListItem[];
  theme?: Theme;
  listType?: AvailableTag;
  ctas?: ILink[];
}

const SectionHoverList: FC<ISectionHoverList> = ({ title, items, ctas, listType = "ul", theme = "default" }) => (
  <Section component="SectionHoverList" theme={theme} size={theme === "default" ? "none" : "xl"}>
    { title && (
      <Container>
        <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
          { title }
        </Text>
        <Spacer size="xl" />
      </Container>
    ) }
    <HoverList items={items} listType={listType} />
    { !!ctas?.length && (
      <>
        <Spacer size="xl" />
        <div className="flex justify-center">
          { ctas.map((cta) => (
            <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2">
              { cta.name }
            </Button>
          )) }
        </div>
      </>
    ) }
  </Section>
);

export default SectionHoverList;
