import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";

import {
  StoryBlokImageAccordionList,
  IStoryBlokImageAccordionList,
} from "../StoryBlokImageAccordionList";

export const StoryBlokFormImageAccordionList = (component: IStoryBlokComponent<IStoryBlokImageAccordionList>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokImageAccordionList> = {
    blok: {
      ...component.blok,
      image_accordion_list: [ {
        ...component.blok.image_accordion_list[0],
        title: interpolateString(
          component.blok.image_accordion_list[0].title,
          getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
          Interpolator.FRONTEND,
        ),
        accordion_list: component.blok.image_accordion_list[0].accordion_list.map((accordion) => ({
          ...accordion,
          title: interpolateString(
            accordion.title,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
        })),
      } ],
      richtextOptions: {
        interpolation: {
          values: getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
        },
        tags: {
          MARK_CODE: {
            display: "default",
          },
        },
      },
    },
  };

  return (
    <>
      <StoryBlokImageAccordionList {...formComponent} />
    </>
  );
};
