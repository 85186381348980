import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

import {
  IStoryBlokAlertPrompt,
  StoryBlokAlertPrompt,
} from "../StoryBlokAlertPrompt";

export const StoryBlokFormAlertPrompt = (component: IStoryBlokComponent<IStoryBlokAlertPrompt>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokAlertPrompt> = {
    blok: {
      ...component.blok,
      alert: [
        {
          ...component.blok.alert[0],
          title: interpolateString(
            component.blok.alert[0].title,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
          body: interpolateString(
            component.blok.alert[0].body,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
        },
      ],
    },
  };

  return (
    <>
      <StoryBlokAlertPrompt {...formComponent} />
    </>
  );
};
