import { useMemo } from "react";

import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFieldValue,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

import {
  StoryBlokConditionalCheckList,
  IStoryBlokConditionalCheckList,
} from "../StoryBlokConditionalCheckList";

export const StoryBlokFormConditionalCheckList = (component: IStoryBlokComponent<IStoryBlokConditionalCheckList>) => {

  const data = useMemo(() => {
    const localData: Record<string, string | string[]> = {};
    component.blok.checklist[0].items.forEach((item) => {
      const value = getFieldValue(component.flowId ?? "UNKNOWN", item.condition_key);
      if (value) {
        localData[item.condition_key] = value.data.submitValue as string | string[];
      }
    });
    return localData;

  }, []);

  const formComponent: IStoryBlokComponent<IStoryBlokConditionalCheckList> = {
    blok: {
      ...component.blok,
      title: interpolateString(
        component.blok.title,
        getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
        Interpolator.FRONTEND,
      ),
      data,
    },
  };

  return (
    <>
      <StoryBlokConditionalCheckList {...formComponent} />
    </>
  );
};
