import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";

import SectionMessageBox from "../../../section/SectionMessageBox/SectionMessageBox";
import { IStoryBlokMessageBox } from "../StoryBlokMessageBox";

export const StoryBlokFormMessageBox = ({ flowId, blok }: IStoryBlokComponent<IStoryBlokMessageBox>) => (
  <SectionMessageBox
    {...blok}
    text={interpolateString(
      blok.text,
      getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
      Interpolator.FRONTEND,
    )}
  />
);
