import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Section from "apps/website/components/layout/Section/Section";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Container from "apps/website/components/layout/Container/Container";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Button from "apps/website/components/base/Button/Button";
import CondensedList, {
  ICondensedListProps,
} from "apps/website/components/feature/CondensedList/CondensedList";
import {
  ImageSize,
} from "apps/website/components/feature/CondensedList/CondensedListItem/CondensedListItem.map";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

export interface ISectionCondensedListProps extends ICondensedListProps {
  title?: string;
  imageSize?: ImageSize;
  theme?: Theme;
  ctas?: ILink[];
}

const SectionCondensedList: FC<ISectionCondensedListProps> = ({ title, items, ctas, listType = "ul", theme = "default", imageSize, columns = "default" }) => (
  <Section component="SectionCondensedList" theme={theme} size={theme === "default" ? "none" : "xl"}>
    { title && (
      <Container>
        <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
          { title }
        </Text>
        <Spacer size="xl" />
      </Container>
    ) }
    <Container size="md">
      <Grid>
        <Column justify="center" align="center">
          <CondensedList items={items} listType={listType} imageSize={imageSize} columns={columns}/>
        </Column>
      </Grid>
    </Container>
    { !!ctas?.length && (
      <>
        <Spacer size="xl" />
        <div className="flex justify-center">
          { ctas.map((cta) => (
            <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2">
              { cta.name }
            </Button>
          )) }
        </div>
      </>
    ) }
  </Section>
);

export default SectionCondensedList;
