import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";

import {
  IStoryBlokSectionCondensedList,
  StoryBlokCondensedList,
} from "../StoryBlokCondensedList";

export const StoryBlokFormCondensedList = (component: IStoryBlokComponent<IStoryBlokSectionCondensedList>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokSectionCondensedList> = {
    blok: {
      ...component.blok,
      title: interpolateString(
        component.blok.title,
        getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
        Interpolator.FRONTEND,
      ),
      condensed_list: [ {
        ...component.blok.condensed_list[0],
        items: component.blok.condensed_list[0].items.map((listItem) => ({
          ...listItem,
          title: interpolateString(
            listItem.title,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
          body: interpolateString(
            listItem.body,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
        })),
      } ],
    },
  };

  return (
    <Container>
      <Grid>
        <Column
          spans={{ default: 12, xl: 6 }}
          offset={{ default: 1, xl: 4 }}
          align={"center"}
        >
          <StoryBlokCondensedList {...formComponent} />
        </Column>
      </Grid>
    </Container>
  );
};
