import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

import {
  StoryBlokClusterTagList,
  IStoryBlokSectionClusterTagList,
} from "../StoryBlokClusterTagList";

export const StoryBlokFormClusterTagList = (component: IStoryBlokComponent<IStoryBlokSectionClusterTagList>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokSectionClusterTagList> = {
    blok: {
      ...component.blok,
      title: interpolateString(
        component.blok.title,
        getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
        Interpolator.FRONTEND,
      ),
    },
  };

  return (
    <>
      <StoryBlokClusterTagList {...formComponent} />
    </>
  );
};
