import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

import {
  StoryBlokQuoteCallout,
  IStoryBlokQuoteCallout,
} from "../StoryBlokQuoteCallout";

export const StoryBlokFormQuoteCallout = (component: IStoryBlokComponent<IStoryBlokQuoteCallout>) => {

  const formComponent: IStoryBlokComponent<IStoryBlokQuoteCallout> = {
    blok: {
      ...component.blok,
      quote: [ {
        ...component.blok.quote[0],
        quote: interpolateString(
          component.blok.quote[0].quote,
          getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", "0", "GET_ALL_WITH_REMOVED"),
          Interpolator.FRONTEND,
        ),
      } ],
    },
  };

  return (
    <>
      <StoryBlokQuoteCallout {...formComponent} />
    </>
  );
};
