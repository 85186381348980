import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import BrandComparisonTable from
  "apps/website/components/feature/Table/BrandComparisonTable/BrandComparisonTable";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export interface ISectionBrandComparisonTable {
  title?: string;
  subtitle?: string;
  body?: string;
  theme?: Theme;
  source?: string;
  highlightKatKin?: boolean;
}

const SectionBrandComparisonTable: FC<ISectionBrandComparisonTable> = (
  { title, subtitle, body, theme, source, highlightKatKin },
) => (
  <Section component="SectionBrandComparisonTable" theme={theme}>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
            { title }
          </Text>
        </>
      ) }
      <Grid>
        <Column>
          { subtitle && (
            <>
              <Spacer size="xl" />
              <Text display="subtitle" size={legacySizeCollectionMap.titleMd} align="center" whitespace={{ lg: "preWrap" }}>{ subtitle }</Text>
            </>
          ) }
          { body && (
            <>
              <Spacer size="md" />
              <Text size={legacySizeCollectionMap.bodyLg} align="center" whitespace={{ lg: "preWrap" }}>{ body }</Text>
            </>
          ) }
          <Spacer size="2xl" />
        </Column>
      </Grid>
      <BrandComparisonTable highlightKatKin={highlightKatKin} />
      { source && (
        <>
          <Spacer size="md" />
          <Grid>
            <Column>
              <Text>
                <Text tag="span" display="subtitle">Source:</Text>
              &nbsp;{ source }
              </Text>
            </Column>
          </Grid>
        </>
      ) }
    </Container>
  </Section>
);

export default SectionBrandComparisonTable;
