import { FC, memo } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import {
  IExternalReview,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import Container from "apps/website/components/layout/Container/Container";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import Review from "../../feature/Review/Review";

import { reviewCardThemeMap } from "./SectionTrustPilotFeatureReview.map";

export interface ISectionTrustPilotFeaturedReview {
  review: IExternalReview;
  starsPrefix: string;
  starsSuffix: string;
  starsBody: string;
  footerTitle: string;
  footerBody: string;
  theme: Theme,
}
// When reinstating TrustPilot
// We should:
// add `starsPrefix` and `starsSuffix` back below

const SectionTrustPilotFeaturedReview: FC<ISectionTrustPilotFeaturedReview> = ({ footerTitle, footerBody, review, theme = "light" }) => (
  <Section component="SectionTrustPilotFeaturedReview" theme={theme} size={theme === "default" ? "none" : "xl"}>
    <Container>
      <Grid>
        <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
          <Grid>
            <Column spans={10} offset={2}>
              <Review
                title={review.title}
                hideTitle
                body={`"${review.body}"`}
                bodyDisplay="subtitle"
                author={review.author}
                theme={reviewCardThemeMap[theme]}
                stars={review.stars}
                className="transform -rotate-1"
              />
            </Column>
          </Grid>
          <Spacer size="lg" />
          { /* <Text tag="h2" display="title" size={legacySizeCollectionMap.titleSm} align="center" className="flex items-center justify-center">
              {  interpolate(starsPrefix) }
              <span className="w-40 px-2">
                <TrustPilotStars />
              </span>
              { interpolate(starsSuffix) }
            </Text> */
            /* <Spacer size="sm" />
            <Text align="center" size={legacySizeCollectionMap.bodySm}>{ interpolate(starsBody) }</Text>
            <Spacer size="md" /> */ }
          <Text tag="h3" display="title" size={legacySizeCollectionMap.titleSm} align="center">
            { footerTitle }
          </Text>
          <Spacer size="sm" />
          <Text align="center" size={legacySizeCollectionMap.bodySm}>
            { footerBody }
          </Text>
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default memo(SectionTrustPilotFeaturedReview);
